.hero {
  text-align: center;
  background-color: #282c34;
  color: white;
}

.hero-logo {
  height: 50vmin;
  pointer-events: none;
  padding: 0px;
  margin: -5vmin;
}

.hero-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60vw;
  margin: auto;
  font-size: calc(10px + 2vmin);
}

.hero-tagline {
  padding: 24px 0px 12px;
  font-size: calc(10px + 1vmin);
}

.small-text {
  font-size: calc(10px + 0.5vmin);
}

.padding-6 {
  padding: 6px 0;
}

.padding-2 {
  padding: 2px 0;
}
